import React, { useState } from "react"
import "./info-image.scss"

const InfoImage = ({ props, origin }) => {

    const paragraph = props[origin];

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        paragraph ? paragraph.map((item, index) => {
            return (
                <div className="img-paragraph-container" style={item.paddingTop && item.paddingBottom ? { paddingTop: "50px", paddingBottom: "50px" } : item.paddingTop ? { paddingTop: "50px" } : item.paddingBottom ? { paddingBottom: "50px" } : {}} key={index}>
                    <div className={item.sideImage ? "full-paragraph" : "paragraph-content"}>
                        {
                            item.sideImage ?
                                <div className="side-paragraph uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-match uk-grid-small uk-grid" >
                                    {
                                        item.sideImage == "left" ?
                                            item.image ?
                                                <div className="left-img">
                                                    <img src={item.image} alt="article-image" className="article-image" />
                                                </div>
                                                : ""
                                            : ""
                                    }
                                    <div style={{ display: "block" }} className={item.sideImage == "left" ? "right-side-box" : item.sideImage == "right" ? "left-side-box" : ""}>
                                        {
                                            item.title ?
                                                <div className="paragraph-header">
                                                    {
                                                        item.subtitle ?
                                                            <p>{item.subtitle}</p>
                                                            : ""
                                                    }
                                                    <h1>{item.title}</h1>
                                                </div>
                                                : ""
                                        }
                                        {
                                            item.inline == true ?
                                                <div className="paragraph-description" style={{ textAlign: item.alignment }}>
                                                    {
                                                        item.description.map((itemDes, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {/* {itemDes.image ?
                                                                    <img src={itemDes.image} alt="article-img" className="description-image" />
                                                                    : ""} */}
                                                                    <p>{itemDes.boldTitle ? <b>{itemDes.boldTitle}</b> : ""} {itemDes.text}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                :

                                                <div className="paragraph-description" style={{ textAlign: item.alignment }}>
                                                    {
                                                        item.description.map((itemDes, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {itemDes.titleDes? <h3 style={{margin: "20px 0"}}>{itemDes.titleDes}</h3> : ""}
                                                                    {/* {
                                                                    itemDes.image ?
                                                                        <img src={itemDes.image} alt="article-img" className="description-image" />
                                                                        : ""
                                                                } */}
                                                                    <p>{itemDes.boldTitle ? <b>{itemDes.boldTitle}</b> : ""} {itemDes.text ? itemDes.text : ""}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                        }
                                        {
                                            item.url ?
                                                <div className="btn-box">
                                                    {
                                                        item.modal ?
                                                            <a onClick={openModal} className="btn">{item.label}</a>
                                                            :
                                                            <a href={item.url} className="btn">{item.label}</a>
                                                    }

                                                    {modalIsOpen && (
                                                        <div className="modal-overlay">
                                                            <div className="modal">
                                                                <button onClick={closeModal} className="close-button">&#x2715;</button>
                                                                <div className="video-container">

                                                                    <iframe
                                                                        title="United Legal Office Video"
                                                                        width="560"
                                                                        height="315"
                                                                        src={item.url}
                                                                        allowFullScreen
                                                                    ></iframe>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                                : ""
                                        }
                                    </div>
                                    {
                                        item.sideImage == "right" ?
                                            item.image ?
                                                <div className="right-img">
                                                    <img src={item.image} alt="article-image" className="article-image" />
                                                </div>
                                                : ""
                                            : ""
                                    }
                                </div>
                                :
                                <>

                                    {
                                        item.title ?
                                            <div className="paragraph-header">
                                                {
                                                    item.subtitle ?
                                                        <p>{item.subtitle}</p>
                                                        : ""
                                                }
                                                <h2>{item.title}</h2>
                                            </div>
                                            : ""
                                    }
                                    {
                                        item.inline == true ?
                                            <div className="paragraph-description" style={{ textAlign: item.alignment }}>
                                                {
                                                    item.description.map((itemDes, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {itemDes.image ?
                                                                    <img src={itemDes.image} alt="article-img" className="description-image" />
                                                                    : ""}
                                                                <p>{itemDes.boldTitle ? <b>{itemDes.boldTitle}</b> : ""} {itemDes.text}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            :

                                            <div className="paragraph-description" style={{ textAlign: item.alignment }}>
                                                {
                                                    item.description.map((itemDes, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <h3>{itemDes.titleDes}</h3>
                                                                {
                                                                    itemDes.image ?
                                                                        <img src={itemDes.image} alt="article-img" className="description-image" />
                                                                        : ""
                                                                }
                                                                <p>{itemDes.boldTitle ? <b>{itemDes.boldTitle}</b> : ""} {itemDes.text ? itemDes.text : ""}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                    }
                                    {
                                        item.url ?
                                            <div className="btn-box">
                                                <a href={item.url} className="btn">{item.label}</a>
                                            </div>
                                            : ""
                                    }
                                    {
                                        item.image ?
                                            <div className="image-box" uk-scrollspy="cls: uk-animation-fade; target: .article-image; delay: 500; repeat: false">
                                                <img src={item.image} alt="article-image" className="article-image" />
                                            </div>
                                            : ""
                                    }
                                </>
                        }
                    </div>
                </div>
            )
        }) : ""


    )

}

export default InfoImage