import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { bannerULA, paragraphContent, sliderCompaniesULA, testimonials } from "../utils/aboutInformation";
import ParallaxComponent from "../components/parallax-component/parallax-component"
import Banner from "../components/banner/banner";
import ReviewCards from "../components/review-cards/review-cards";
import InfoImage from "../components/info-image/info-image";
import ImageCarousel from "../components/image-carousel/image-carousel";




// markup
const About = () => {


  return (
    <Layout
      SEOTitle={"About - United Legal Assistance"}
      SEODescription={"United Legal Assistance is a friendly, honest and professional team with over 20 years experience in the insurance and claims management industry."}
      footerDisclaimer={""}>

      <InfoImage props={paragraphContent} origin="about" />

      <Banner props={bannerULA} />

      <ImageCarousel props={sliderCompaniesULA} />

      <ImageCarousel props={testimonials} />

      {/* <ParallaxComponent props={aboutUsParallax} />

      <ReviewCards props={reviewList} />

      <Banner props={lastBanner} /> */}

    </Layout>
  )

}

export default About